import classNames from "classnames";
import React from "react";

import { wait } from "@app/utils/wait";
import { TimeProps, Span } from "./Span";
import { LineChildren } from "./LineChildren";
import { Progress } from "./Progress";

export interface LineProps extends TimeProps {
  children: React.ReactNode | React.ReactNode[];
  className?: string;
  isActive?: boolean;
  isDone?: boolean;
  onFinish?: () => void;
  noPrompt?: boolean;
  type?: "progress"; // only for special nodes
}

const Line = ({
  type,
  children,
  className,
  isActive,
  isDone,
  onFinish,
  noPrompt,
  postDelay = 1000,
  isImmediate = false,
  ...props
}: LineProps) => {
  const isProgress = type === "progress";
  const justText = typeof children === "string" && !isProgress;
  const hasChildren = typeof children === "object" && !isProgress;

  const gotoNextLine = async () => {
    if (!isImmediate) await wait(postDelay);
    if (typeof onFinish === "function") onFinish();
  };

  if (children == null) return null;
  if (!isActive && !isDone) return null;

  return (
    <div
      className={classNames("terminal-line", className, {
        "terminal-line--active": isActive,
        "terminal-line--done": isDone,
        "terminal-line--noPrompt": noPrompt,
        ...props,
      })}
    >
      {isProgress && <Progress isActive onFinish={gotoNextLine} {...props} />}
      {hasChildren && (
        <LineChildren
          items={children}
          renderItem={(item, isActive, onFinish, i) => (
            <Span
              isImmediate={isImmediate}
              key={i}
              isActive={isActive}
              onFinish={onFinish}
              {...item.props}
            >
              {item.props.children}
            </Span>
          )}
          onDone={gotoNextLine}
        />
      )}
      {justText && (
        <Span
          isActive
          onFinish={gotoNextLine}
          isImmediate={isImmediate}
          {...props}
        >
          {children}
        </Span>
      )}
    </div>
  );
};

export { Line };
