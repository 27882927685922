import React, { useState } from "react";
import { PAGES } from "@app/constants";
import { Button, Modal } from "@app/components";
import { EVENTS, trackEvent } from "@app/utils/trackEvent";
import {
  Apple,
  Clock,
  Feed,
  GodotLogoFull,
  Phone,
  Play,
  Smile,
  Programming,
  Routing,
  Guide,
  CardSearch,
  Secure
} from "@app/components/Icons";

import { Typing } from "./Typing";
import { QuickstartForm } from "./QuickstartForm";
import { MetaData } from "../_Shared";

import adminImage from "@assets/images/admin.png";

interface Feature {
  icon: React.ReactNode;
  headline: string;
  text: string;
}

const FEATURES: Feature[] = [
  {
    icon: <Feed />,
    headline: "Supports iOS and Android",
    text: "Secure and scalable, rstream's Private / Public Tunnels connect local to global via TLS/HTTPS. Ensuring seamless and fortified remote access, it's the bridge for secure data paths."
  },
  {
    icon: <Programming />,
    headline: "Build and upload with one command",
    text: "Utilize TLS encryption for all communications, ensuring your data remains secure and private. rstream enforces top-tier security protocols, safeguarding your information from unauthorized access."
  },
  {
    icon: <Routing />,
    headline: "Integrates with existing CI",
    text: "rstream enhances security with Token-Based Authentication, offering fine-grained control over access permissions. Determine who connects and to which resources, ensuring each tunnel is authenticated and authorized for utmost security."
  },
  {
    icon: <Guide />,
    headline: "Guided setup wizard and documentation",
    text: "rstream is built for true cross-platform support, operating effortlessly on Linux, macOS, Windows, and beyond. From embedded systems to large-scale servers, enjoy consistent performance and secure tunnels across all your devices."
  },
  {
    icon: <CardSearch />,
    headline: "View and download build logs from the dashboard",
    text: "Gain valuable insights with rstream's Operational Monitoring, providing real-time metrics and usage statistics to oversee your network's performance. Monitor the health and efficiency of your tunnels, optimizing for reliability and speed."
  },
  {
    icon: <Secure />,
    headline: "Secure management of credentials",
    text: "Dive into our detailed documentation, designed to streamline your rstream adoption and usage. Whether you are a beginner or an expert, our guides cover everything you need to know to deploy and manage your secure tunnels efficiently."
  }
];

const Home = () => {
  const [showEmailModal, setShowEmailModal] = useState(false);

  return (
    <main>
      <MetaData page={PAGES.Home} />
      <section className="hero">
        <div className="hero-left">
          <div className="_mb10">
            <Play />
            <Phone />
            <Smile />
          </div>
          <h1 className="hero-h1">App Store ready Godot games in minutes.</h1>
          <p className="hero-p">
            Focus on building great games while we take care of getting them
            shipped to the <b style={{ color: "white" }}>App Store</b> and{" "}
            <b style={{ color: "white" }}>Google Play</b>.{" "}
          </p>
          <div className="hero-buttons">
            <Button
              size="md"
              className="_mt10"
              variant="secondary"
              href="/docs/guides/quick-start"
            >
              Quick start
            </Button>
            <Button
              size="md"
              className="_mt10 hero-quickStartEmail"
              variant="secondary"
              onClick={() => {
                setShowEmailModal(true);
                trackEvent(EVENTS.QUICKSTART_MODAL_OPEN);
              }}
            >
              Email me the Quick start guide.
            </Button>

            <Modal
              isShowing={showEmailModal}
              onClose={() => setShowEmailModal(false)}
            >
              <QuickstartForm />
            </Modal>

            <Button
              size="md"
              className="_mt10"
              variant="primary"
              href="https://npmjs.com/package/shipthis"
            >
              Get ShipThis
            </Button>
          </div>
        </div>
        <div className="hero-right">
          <Typing />
        </div>
      </section>

      <section className="intro">
        <div className="intro-pre intro--maxWidth">Features</div>
        <h3 className="intro-h3 intro--maxWidth">
          Cloud-build your Godot games -<br />
          No Xcode or Android Studio needed
        </h3>
        <div className="intro-p intro--maxWidth">
          Once configured, ShipThis can rebuild new versions of your game with a
          single command. ShipThis will handle the entire build process,
          including code signing, and uploading to the App Store and Google
          Play.
        </div>
        <img src={adminImage} alt="shipthis admin" className="intro-image" />
        {/* <ul className="intro-features">
          {FEATURES.map(({ icon, headline, text }) => (
            <li className="intro-feature" key={headline}>
              <div className="intro-feature-icon">{icon}</div>
              <div className="intro-feature-wrap">
                <h4 className="intro-feature-headline">{headline}</h4>
                <p className="intro-feature-text">{text}</p>
              </div>
            </li>
          ))}
        </ul> */}
      </section>

      <section className="features grid grid--2col">
        <div className="featureList-cloud2" />
        <div className="featureList-wrap">
          <h2 className="features-headline">ShipThis Features</h2>
          <ul className="featureList">
            <li>Guided Setup</li>
            <li>Build with one command</li>
            <li>Credentials are managed for you</li>
            <li>Monitor builds in the dashboard</li>
            <li>View error logs and build history</li>
            <li>Integrates with existing CI</li>
          </ul>
          <div className="_flex _flex-center _mt10">
            <Apple fill="white" className="_mr10" width={43} height={43} />
            <GodotLogoFull height={55} width={131} />
          </div>
        </div>
      </section>
    </main>
  );
};

export { Home };
