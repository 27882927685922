import React, { useState } from "react";
import { Button, PopUp } from "@app/components";

const DeleteConfirmationPopup = ({ open, deleteFn, onClose }) => (
  <PopUp
    open={open}
    onClose={onClose}
    title="Are you sure you want to delete your account?"
  >
    <p style={{ fontSize: "1.4rem", lineHeight: 1.3, marginBottom: 10 }}>
      This will permanently delete your account and all associated data.{" "}
      <b>This action cannot be undone</b>. <br />
      <br />
      We process deletion requests manually - which may take up to 30 days.
      <br />
      <br />
      If you're <b>sure</b>, click <b>Delete</b> to proceed. Otherwise, click{" "}
      <b>Cancel</b> to keep your account.
    </p>
    <div className="popup-buttonGroup">
      <Button size="md" onClick={onClose} isCancel>
        Cancel
      </Button>
      <Button size="md" onClick={deleteFn}>
        Delete
      </Button>
    </div>
  </PopUp>
);

export { DeleteConfirmationPopup };
