import classNames from "classnames";
import React, { Suspense, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";

import { useIsSignedIn } from "@app/utils/query";
import { useLockBody } from "@app/utils/hooks";
import { Avatar, Button, Logo, Navigation, Spinner, X } from "@app/components";
import { AuthPopOver, useAuthPopOver } from "@app/components/PopOver/Auth";

interface NavLinksProps extends React.ComponentProps<"ul"> {}

const NavLinks = ({ className }: NavLinksProps) => (
  <ul className={classNames("nav", className)}>
    <li className="nav_item nav_item--home">
      <Button href="/">Homepage</Button>
    </li>
    <li className="nav_item">
      <Button href="/docs">Docs</Button>
    </li>
    <li className="nav_item">
      <Button href="/pricing">Pricing</Button>
    </li>
  </ul>
);

interface AuthProps extends React.ComponentProps<"div"> {}

const Auth = ({ className }: AuthProps) => {
  const navigate = useNavigate();
  const { data: isSignedIn } = useIsSignedIn();
  const { show: showPopOver, toggle } = useAuthPopOver();

  return (
    <div className={classNames(className)}>
      <Suspense fallback={<Spinner />}>
        {isSignedIn ? (
          <div className="_flex">
            <Button
              size="sm"
              variant="secondary"
              className="header_logInButton"
              onClick={() => navigate("/dashboard")}
            >
              Dashboard
            </Button>
            <Avatar size={40} className="_ml10" onClick={toggle}>
              <AuthPopOver showPopOver={showPopOver} toggle={toggle} />
            </Avatar>
          </div>
        ) : (
          <Button
            size="sm"
            className="header_logInButton"
            onClick={() => navigate("/login")}
          >
            Log in / Sign up
          </Button>
        )}
      </Suspense>
    </div>
  );
};

const MobileNav = () => {
  return (
    <div className="header_mobileNav">
      <Auth className="nav-auth--mobile" />
      <NavLinks className="nav--mobile" />
    </div>
  );
};

const MobileNavButton = ({
  showMobileNav,
  toggleNav,
}: {
  showMobileNav: boolean;
  toggleNav: () => void;
}) => {
  const Component = showMobileNav ? X : Navigation;
  return <Component className="header_mobileNavButton" onClick={toggleNav} />;
};

const Header = () => {
  const [showMobileNav, setShowMobileNav] = useState(false);

  const toggleNav = (newState?: boolean) => {
    setShowMobileNav(!showMobileNav);
  };

  useLockBody(showMobileNav, !showMobileNav, [showMobileNav]);

  return (
    <header className="header">
      <Logo className="header_logo" withText withBeta size="sm" />
      <NavLinks className="nav--desktop" />
      <MobileNavButton showMobileNav={showMobileNav} toggleNav={toggleNav} />
      <Auth className="nav-auth" />
      {showMobileNav && <MobileNav />}
    </header>
  );
};

export { Header };
