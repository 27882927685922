import axios, { AxiosError } from "axios";
import { UseQueryResult, useQuery } from "@tanstack/react-query";

import { GDPRRequest, Self } from "@app/types";
import { API_URL, cacheKeys } from "@app/constants";
import { castArrayObjectDates, castObjectDates } from "@app/utils/dates";

import { getAuthHeaders } from "./useIsSignedIn";

export async function fetchGdprStatus(): Promise<GDPRRequest[]> {
  try {
    const headers = getAuthHeaders();
    const response = await axios.get(`${API_URL}/me/gdpr`, { headers });
    return castArrayObjectDates<GDPRRequest>(response.data);
  } catch (error) {
    console.warn("fetchSelf Error", error);
    throw error;
  }
}

export const useGdprStatus = (): UseQueryResult<GDPRRequest[]> => {
  return useQuery<GDPRRequest[], AxiosError>({
    queryKey: cacheKeys.gdpr,
    queryFn: fetchGdprStatus,
    refetchInterval: 1000 * 60,
    staleTime: 1000 * 60,
  });
};
