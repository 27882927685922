import React, { useState } from "react";
import {
  Box,
  Button,
  Crown,
  FormControl,
  Spinner,
  Tag,
  TextInput,
} from "@app/components";

import { Header } from "../_Shared";
import {
  useGdprDeleteMutation,
  useGdprExportMutation,
  useGdprStatus,
  useSelf,
} from "@app/utils/query";
import { DeleteConfirmationPopup } from "./DeleteConfirmationPopup";

const BetaDetails = () => {
  const { isLoading, data } = useSelf();

  if (!isLoading && !data?.isBetaUser) return;

  return (
    <div className="_mb30">
      <div className="account-headline">
        <Crown width={18} height={18} className="gameOverview-headerIcon" />
        <h2>
          You have a <strong>Beta Tester</strong> account
        </h2>
      </div>
      <section className="admin-section ">
        <p>
          While in beta, ShipThis is completely free to use. After beta, we’ll
          introduce affordable pricing, but our early Beta Testers will get
          exclusive discounts or free access to certain features!
        </p>
      </section>
    </div>
  );
};

const GeneralDetails = () => {
  const { isLoading, data } = useSelf();

  const Fields = () => (
    <div className="grid grid--2col">
      <FormControl label="Account Type">
        <TextInput
          title="Account Type"
          value={data?.accountType}
          onChange={() => {}}
          isDisabled
        />
      </FormControl>
      <FormControl label="Email">
        <TextInput
          title="Email"
          placeholder="mysweet@email.com"
          value={data?.email}
          onChange={() => {}}
          isDisabled
        />
      </FormControl>
    </div>
  );

  return (
    <div className="_mb30">
      <div className="account-headline">
        <Box width={18} height={18} className="gameOverview-headerIcon" />
        <h2>Your Account</h2>
      </div>
      <section className="admin-section">
        {isLoading ? <Spinner /> : <Fields />}
      </section>
    </div>
  );
};

const GdprDetails = () => {
  const { isLoading, data } = useGdprStatus();
  const gdprExportMutation = useGdprExportMutation();
  const gdprDeleteMutation = useGdprDeleteMutation();
  const [showDeleteConf, setShowDeleteConf] = useState(false);

  const exportRequested =
    data &&
    data.some(({ type, status }) => type === "EXPORT" && status === "PENDING");

  const deleteRequested =
    data &&
    data.some(({ type, status }) => type === "DELETE" && status === "PENDING");

  if (isLoading) return <Spinner />;

  const Fields = () => (
    <div>
      <DeleteConfirmationPopup
        open={showDeleteConf}
        onClose={() => {
          setShowDeleteConf(false);
        }}
        deleteFn={() => {
          setShowDeleteConf(false);
          gdprDeleteMutation.mutate();
        }}
      />
      <div className="_mb20">
        We take your privacy seriously. You can request a copy of your data or
        delete your account at any time.
        <br />
        <br />
        All requests are processed manually to ensure security and compliance
        with our <a href="/privacy">Privacy Policy</a>. This may take up to 30
        days, but we'll do our best to handle it as quickly as possible.
        <br />
        <br />
        If you have any questions, feel free to reach out — we're happy to help!
      </div>

      <div className="_flex _flex-center _flex-spaceBetween _mb20">
        <div className="account-label">Request your data</div>
        <>
          {exportRequested ? (
            <div style={{ color: "aquamarine", paddingRight: 7 }}>
              Export requested
            </div>
          ) : (
            <Button variant="dark" onClick={() => gdprExportMutation.mutate()}>
              Request Export
            </Button>
          )}
        </>
      </div>

      <div className="_flex _flex-center _flex-spaceBetween ">
        <div className="account-label">Request account deletion</div>

        <>
          {deleteRequested ? (
            <div style={{ color: "aquamarine", paddingRight: 7 }}>
              Delete requested
            </div>
          ) : (
            <Button
              variant="dark"
              style={{ color: "#ff7878" }}
              onClick={() => setShowDeleteConf(true)}
            >
              Delete Account
            </Button>
          )}
        </>
      </div>
    </div>
  );

  return (
    <div className="_mb30">
      <div className="account-headline">
        <Tag width={18} height={18} className="gameOverview-headerIcon" />
        <h2>Your Data</h2>
      </div>
      <section className="admin-section">
        {isLoading ? <Spinner /> : <Fields />}
      </section>
    </div>
  );
};

const Account = () => {
  return (
    <main className="admin-main">
      <Header title="Account" />
      <div className="account">
        <GeneralDetails />
        <BetaDetails />
        <GdprDetails />
      </div>
    </main>
  );
};

export { Account };
