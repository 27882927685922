type ConfigURLs = {
  api: string;
  ws: string;
};

function getConfigURLs(): ConfigURLs {
  // Handling server side rendering
  const hostname =
    typeof window !== "undefined" ? window.location.hostname : "localhost";
  switch (hostname) {
    // Prod
    case "shipthis.cc":
    case "shipth.is":
      return {
        api: `https://api.shipthis.cc/api/1.0.0`,
        ws: `wss://ws.shipthis.cc`,
      };
    // Staging and local
    case "develop.shipthis.cc":
    case "develop.shipth.is":
    case "localhost":
    case "127.0.0.1":
      return {
        api: `https://api.develop.shipthis.cc/api/1.0.0`,
        ws: `wss://ws.develop.shipthis.cc`,
      };
    // Local via ngrok
    default:
      return { api: `https://${hostname}/api/1.0.0`, ws: `wss://${hostname}` };
  }
}

const urls = getConfigURLs();
export const WS_URL = urls.ws;
export const API_URL = urls.api;
