import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { cacheKeys } from "@app/constants";
import { setCurrentJWT, queryClient } from "@app/utils/query";
import { useClickAway } from "@app/utils/hooks/useClickAway";
import { Dashboard, Home, LogOut, PopOver, Profile } from "@app/components";

interface Props {
  showPopOver: boolean;
  toggle?: () => void;
}

const useAuthPopOver = () => {
  const [show, setShow] = useState(false);
  const toggle = () => setShow(!show);
  return { show, toggle };
};

const AuthPopOver = ({ showPopOver, toggle }: Props) => {
  const navigate = useNavigate();
  const ref = useClickAway(toggle);

  const handleLogout = () => {
    setCurrentJWT(null);
    queryClient.invalidateQueries({ queryKey: cacheKeys.isSignedIn });
    queryClient.invalidateQueries({ queryKey: cacheKeys.self });
    navigate("/");
  };

  return (
    <PopOver
      clickAwayRef={ref}
      isShowing={showPopOver}
      className="popOver--auth"
    >
      <ul className="popOver-list">
        <li className="popOver-listItem">
          <a href="/account" className="popOver-link">
            <Profile width={18} height={18} className="popOver-icon" />
            Account
          </a>
        </li>
        <li className="popOver-listItem">
          <Link to="/" className="popOver-link">
            <Home width={18} height={18} className="popOver-icon" />
            Homepage
          </Link>
        </li>
        <li className="popOver-listItem">
          <a href="/docs" className="popOver-link">
            <Dashboard width={18} height={18} className="popOver-icon" />
            Docs
          </a>
        </li>
        <li className="popOver-listItem">
          <Link to="/" onClick={handleLogout} className="popOver-link">
            <LogOut width={18} height={18} className="popOver-icon" />
            Logout
          </Link>
        </li>
      </ul>
    </PopOver>
  );
};

export { AuthPopOver, useAuthPopOver };
