import React from "react";
import classNames from "classnames";

import { Terminal, Line, Span, Progress } from "@app/components/Terminal";
import { showToast } from "@app/utils/emitters";

const Typing = () => {
  const [showInstallProgress, setShowInstallProgress] = React.useState(true);
  const [showShipProgress, setShowShipProgress] = React.useState(true);
  return (
    <Terminal
      waitForVisibility={false}
      mode="dark"
      afterCopy={() => showToast("Text Copied.")}
      className="hero-terminal"
    >
      <Line className="nearwhite" postDelay={1000}>
        <Span postDelay={100} typeDelay={50} preDelay={1000}>
          npm
        </Span>
        <Span postDelay={0}> </Span>
        <Span postDelay={200} className="blue" typeDelay={50}>
          install -g
        </Span>
        <Span postDelay={0}> </Span>
        <Span postDelay={500} className="red" typeDelay={40}>
          shipthis
        </Span>
      </Line>

      <Line
        noPrompt
        type="progress"
        className={classNames({
          fadeOut: !showInstallProgress,
        })}
        onFinish={() => setTimeout(() => setShowInstallProgress(false), 500)}
      >
        <Progress />
      </Line>
      <Line
        className={classNames({
          fadeOut: !showInstallProgress,
        })}
        noPrompt
        typeDelay={0}
        postDelay={50}
      >
        added 231 packages in 6s
      </Line>

      <Line
        className={classNames("green", {
          fadeOut: !showInstallProgress,
        })}
        noPrompt
        typeDelay={0}
        postDelay={50}
      >
        # ShipThis installed 😎
      </Line>

      <Line className="nearwhite" postDelay={1000}>
        <Span postDelay={500} className="red" typeDelay={40}>
          shipthis
        </Span>
        <Span postDelay={0}> </Span>
        <Span postDelay={100} typeDelay={50} className="blue">
          login
        </Span>
      </Line>

      <Line className="nearwhite" postDelay={1000}>
        <Span postDelay={500} className="red" typeDelay={40}>
          shipthis
        </Span>
        <Span postDelay={0}> </Span>
        <Span postDelay={100} typeDelay={50} className="blue">
          game
        </Span>
        <Span postDelay={0}> </Span>
        <Span postDelay={100} typeDelay={50}>
          wizard
        </Span>
      </Line>

      <Line className="nearwhite" postDelay={1000}>
        <Span postDelay={500} className="red" typeDelay={40}>
          shipthis
        </Span>
        <Span postDelay={0}> </Span>
        <Span postDelay={100} typeDelay={50} className="blue">
          game
        </Span>
        <Span postDelay={0}> </Span>
        <Span postDelay={100} typeDelay={50}>
          ship
        </Span>
      </Line>
      <Line
        noPrompt
        type="progress"
        className={classNames({
          fadeOut: !showShipProgress,
        })}
        onFinish={() => setTimeout(() => setShowShipProgress(false), 250)}
      >
        <Progress />
      </Line>
      <Line className="green" noPrompt typeDelay={0} postDelay={50}>
        🚀 Shipped
      </Line>
    </Terminal>
  );
};

export { Typing };
