import axios from "axios";
import { useMutation } from "@tanstack/react-query";

import { API_URL, cacheKeys } from "@app/constants";
import { queryClient, getAuthHeaders } from "@app/utils/query";

export const useGdprExportMutation = () =>
  useMutation({
    mutationFn: () => {
      const headers = getAuthHeaders();
      const url = `${API_URL}/me/gdpr/export`;
      return axios.post(url, null, { headers });
    },
    onError: (error) => {
      console.log("An useGdprExportMutation error happened!", error);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: cacheKeys.gdpr });
    }
  });
